<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="transferVoucher === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="transferVoucher"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="transferVoucher"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <div
            class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
          >
            <div style="color: black">
              <div class="logo-wrapper">
                <img
                  src="@/assets/images/logo/logo.png"
                  width="300px"
                  height="100%"
                >
                <h3 class="text-primary invoice-logo" />
              </div>
            </div>
          </div>

          <b-card-body class="d-flex flex-row justify-content-between">
            <div style="color: black">
              <div class="ml-1">
                <h1 style="color: black; font-size: 30px">
                  Bon de transfert
                </h1>
              </div>
              <div>
                <h3
                  class="ml-1"
                  style="color: black"
                >
                  <span style="color: black">De {{ transferVoucher.from_repository.name }} à {{ transferVoucher.to_repository.name }}</span>
                </h3>
              </div>
              <div>
                <h3
                  class="ml-1"
                  style="color: black"
                >
                  Date:
                  <span style="color: black"> {{ formatFn(transferVoucher.created_at) }}</span>
                </h3>
              </div>
              <div>
                <h3
                  class="ml-1"
                  style="color: black"
                >
                  N° Colis:
                  <span style="color: black"> {{ transferVoucher.orders.length }}</span>
                </h3>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="mb-2 mt-0 ml-2"
                style="border: 2px solid black"
              >
                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h3"
                  style="color: black"
                >
                  <strong>{{ company.name }} :</strong> Société de livraison à domicile
                </b-card-text>
                <b-card-text
                  class="ml-2 h3 mr-2 mb-1"
                  style="color: black"
                >
                  <strong>Matricule fiscale :</strong> {{ company.tax_identification_number }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color: black"
                >
                  <strong>Adresse : </strong>{{ company.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Tel :</strong> {{ company.phone1 }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Chauffeur :</strong> {{ transferVoucher.delivery_man.username }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h3"
                  style="color:black"
                >
                  <strong>Voiture :</strong> {{ transferVoucher.car.serial_number }}
                </b-card-text>
              </div>
            </div>

          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body class="invoice-padding pt-0">
            <b-table-lite
              class="ta"
              responsive
              :items="orders"
              :fields="[
                'code',
                'fournisseur',
                'gouvernorat',
                'tel',
                'destinataire',
                'adresse',
                'prix',
              ]"
            >
              <!--  <template
                #cell(code)="data"
              >
                <qrcode-vue
                  :value="data.item.code.toString()"
                  :size="size"
                  level="H"
                />
                <span>{{ data.item.code.toString() }}</span>
              </template> -->
              <template
                #cell(code)="data"
              >
                <barcode
                  :value="data.item.code.toString()"
                />
                <h4
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold; text-align:center;"
                >
                  Echange
                </h4>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}{{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <!-- Invoice Description: Total -->

          <b-card-body class="d-flex justify-content-between mt-5">
            <!-- Col: Total -->

            <div class="mb-5 ml-1">
              <h3 style="color: black; font-size: 25px">
                Signature
              </h3>
            </div>
            <div>
              <h1 style="color: black; font-size: 25px">
                Total TTC : {{ transferVoucher.total.toFixed(3) }} DT
              </h1>
            </div>
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-if="!isLoading"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            <b-icon
              icon="printer"
              class="mr-1"
            />
            Imprimer
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'
// import QrcodeVue from 'qrcode.vue'
import VueBarcode from 'vue-barcode'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    // QrcodeVue,
    barcode: VueBarcode,

  },
  data() {
    return {
      transferVoucher: {},
      orders: [],
      size: 40,
      isLoading: false,
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getTransferVoucher()
    this.company = storeAuth.state.company
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },

    async getTransferVoucher() {
      this.isLoading = true
      const response = await axios.get(`/api/transfer-vouchers/detail/${this.$route.params.id}`)
      this.transferVoucher = response.data
      const { orders } = this.transferVoucher
      for (let i = 0; i < orders.length; i += 1) {
        const data = {
          code: orders[i].id,
          gouvernorat: orders[i].governorate.name,
          fournisseur: orders[i].supplier.username,
          tel: orders[i].phone1,
          destinataire: orders[i].name,
          adresse: orders[i].address,
          prix: orders[i].price_ttc,
          is_an_exchange: orders[i].is_an_exchange,
        }
        this.orders.push(data)
        this.isLoading = false
      }
    },
  },
  setup() {
    const total = 0
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
      total,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor {
  background-color: #208a9d;
}
.contact {
  background-color: #208a9d;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
  .ta {
    overflow: visible;
    font-size: 18px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor {
    background-color: #208a9d;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #208a9d;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
